// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.store/css-loader@6.8.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.store/css-loader@6.8.1/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/.store/css-loader@6.8.1/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/balance-two.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../images/eyes.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../images/jijin.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1500px) {
  .fin_quality {
    padding: 138px 20px 0 !important;
  }
}
.fin_quality {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 138px 271px 0;
  align-items: center;
}
.fin_quality > div:first-child {
  width: 44%;
}
.fin_quality > div:last-child {
  flex: 0.9;
}
.fin_quality > div:last-child :first-child {
  margin-bottom: 20px;
}
.fin_balance {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  background-size: 100px 100px;
}
.fin_eye {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) !important;
  background-size: 100px 100px;
}
.fin_social {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) !important;
  background-size: 100px 100px;
}
.other_article {
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 200px;
}
.other_article a {
  margin: 0 20px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/css/finance.less"],"names":[],"mappings":"AAAA;EACI;IACI,gCAAA;EACN;AACF;AACA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AAPA;EAQQ,UAAA;AAER;AAVA;EAWQ,SAAA;AAER;AAbA;EAaY,mBAAA;AAGZ;AAEA;EACI,oEAAA;EACA,4BAAA;AAAJ;AAEA;EACI,oEAAA;EACA,4BAAA;AAAJ;AAEA;EACI,oEAAA;EACA,4BAAA;AAAJ;AAEA;EACI,aAAA;EACA,gBAAA;EACA,2BAAA;EACA,eAAA;EACA,qBAAA;AAAJ;AALA;EAOQ,mBAAA;AACR","sourcesContent":["@media screen and (max-width:1500px){\n    .fin_quality{\n        padding: 138px 20px 0 !important;\n    }\n}\n.fin_quality{\n    width: 100%;\n    height: auto;\n    display: flex;\n    justify-content: space-between;\n    padding: 138px 271px 0;\n    align-items: center;\n    >div:first-child{\n        width: 44%;\n    }\n    >div:last-child{\n        flex: 0.9;\n        :first-child{\n            margin-bottom: 20px;\n        }\n    }\n    \n}\n.fin_balance{\n    background-image: url('../images/balance-two.png') !important;\n    background-size: 100px 100px;\n}\n.fin_eye{\n    background-image: url('../images/eyes.png') !important;\n    background-size: 100px 100px;\n}\n.fin_social{\n    background-image: url('../images/jijin.png') !important;\n    background-size: 100px 100px;\n}\n.other_article{\n    display: flex;\n    overflow: hidden;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    padding-bottom: 200px;\n    a{\n        margin: 0 20px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
