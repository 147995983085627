import '../css/partdem.css'
import '../css/index.css'
function getQueryVariable() {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    let params = {}
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        params[pair[0]] = pair[1]
    }
    return params
}
window.onload = () => {
    let par = getQueryVariable()
    switch (par.type) {
        case '1':
            let parta = document.getElementById('part_a').offsetTop
            window.scrollTo({ top: parta - 60, behavior: 'smooth' })
            break;
        case '2':
            let partb = document.getElementById('part_b').offsetTop
            window.scrollTo({ top: partb - 60, behavior: 'smooth' })
            break;
        case '3':
            let partc = document.getElementById('part_c').offsetTop
            window.scrollTo({ top: partc - 60, behavior: 'smooth' })
            break;
        case '4':
            let partd = document.getElementById('part_d').offsetTop
            window.scrollTo({ top: partd, behavior: 'smooth' })
            break;
        case '5':
            let parte = document.getElementById('part_e').offsetTop
            window.scrollTo({ top: parte - 60, behavior: 'smooth' })

            break;
        case '6':
            let partf = document.getElementById('part_f').offsetTop
            window.scrollTo({ top: partf - 60, behavior: 'smooth' })
            break;
        case '7':
            let partg = document.getElementById('part_g').offsetTop
            window.scrollTo({ top: partg - 60, behavior: 'smooth' })
            break;
        case '8':
            let parth = document.getElementById('part_h').offsetTop
            window.scrollTo({ top: parth - 60, behavior: 'smooth' })
            break;
        case '9':
            let parti = document.getElementById('part_i').offsetTop
            window.scrollTo({ top: parti - 60, behavior: 'smooth' })
            break
    }

    $(function () {
        if(par.arType >= 1 && par.arType <= 3){
            var h1Title = `<p><i>公众参与</i><br><span>Participatory Democracy</span></p>`
            $('#h1_title').html(h1Title)
        }else if (par.arType >= 4 && par.arType <= 6) {
            var h1Title = `<p><i>教育公平</i><br><span>Right to Education</span></p>`
            $('#h1_title').html(h1Title)
        }else{
            var h1Title = `<p><i>金融透明</i><br><span>Development and Finance</span></p>`
            $('#h1_title').html(h1Title)
        }
        switch (par.arType){
            case '1':
                $('#title_bg').addClass('pandero1')
                var h2Title = `<p>参与式预算研究</p><p>Researches on PB</p>`
                $('#h2_title').html(h2Title) 
            break;
            case '2':
                $('#title_bg').addClass('shuzihua')
                var h2Title = `<p>数字化参与系统</p><p>Digitalization of PB</p>`
                $('#h2_title').html(h2Title) 
            break;
            case '3':
                $('#title_bg').addClass('anli')
                var h2Title = `<p>国内外案例研究</p><p>Case Studies</p>`
                $('#h2_title').html(h2Title) 
            break;
            case '4':
                var h2Title = `<p>民生</p><p>People's livelihood</p>`
                $('#h2_title').html(h2Title) 
            break;
            case '5':
                $('#title_bg').addClass('phone')
                var h2Title = `<p>教育公平</p><p>Education gap in equality</p>`
                $('#h2_title').html(h2Title) 
            break;
            case '6':
                $('#title_bg').addClass('edu_bg')
                var h2Title = `<p>教育研究倡导</p><p>Research and Advocacy</p>`
                $('#h2_title').html(h2Title) 
            break;
            case '7':
                $('#title_bg').addClass('fin_balance')
                var h2Title = `<p>税收与社会均衡发展</p><p>Taxation and inequality</p>`
                $('#h2_title').html(h2Title) 
            break;
            case '8':
                $('#title_bg').addClass('fin_eye')
                var h2Title = `<p>金融透明</p><p>Finance transparency</p>`
                $('#h2_title').html(h2Title) 
            break;
            case '9':
                $('#title_bg').addClass('fin_social')
                var h2Title = `<p>社会金融</p><p>Case Studies</p>`
                $('#h2_title').html(h2Title) 
            break;
        }
    })
    $('#edu_more1').click(function(){
        $(location).attr('href','other.html?arType=4')
    })
    $('#edu_more2').click(function(){
        $(location).attr('href','other.html?arType=5')
    })
    $('#edu_more3').click(function(){
        $(location).attr('href','other.html?arType=6')
    })
    $('#fin_more1').click(function(){
        $(location).attr('href','other.html?arType=7')
    })
    $('#fin_more2').click(function(){
        $(location).attr('href','other.html?arType=8')
    })
    $('#fin_more3').click(function(){
        $(location).attr('href','other.html?arType=9')
    })
    $('#par_more1').click(function(){
        $(location).attr('href','other.html?arType=1')
    })
    $('#par_more2').click(function(){
        $(location).attr('href','other.html?arType=2')
    })
    $('#par_more3').click(function(){
        $(location).attr('href','other.html?arType=3')
    })
}

